<template>
  <div>
    <v-card elevation="0" v-if="uploadedFile">
      <v-list class="grey lighten-3 pa-0">
        <v-list-item dense>
          <v-list-item-avatar color="grey lighten-3" class="mr-2">
            <v-icon>mdi-attachment</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>{{ $t("hr.employee.education.licence_file") }}</v-list-item-title>
          </v-list-item-content>
          <v-list-item-action
            style="width: auto !important; flex-direction: row !important; padding: 0; align-items: center"
          >
            <v-btn icon :loading="downloading" :disabled="deleting" color="teal" :href="publicURL" target="_blank">
              <v-icon>mdi-arrow-down-bold-box</v-icon>
            </v-btn>
            <v-btn
              v-if="!readonly"
              icon
              :loading="deleting"
              :disabled="downloading"
              color="primary"
              @click.stop="onDelete"
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </v-card>
    <v-text-field
      v-else
      v-model="uploadedFile"
      color="tertiary"
      outlined
      readonly
      append-icon="mdi-attachment"
      :loading="fileUploading"
      dense
      hide-details
      @click="pickFile"
    />

    <input type="file" style="display: none" ref="file" :accept="acceptFiles" @change="onFilePicked" />
  </div>
</template>

<script>
  export default {
    name: "LicenceFile",
    components: {},
    props: {
      value: {
        type: String
      },
      publicURL: {
        type: String
      },
      readonly: Boolean
    },
    data: () => ({
      loading: false,
      uploadedFile: null,
      fileUrl: null,
      fileUploadValue: 100,
      fileUploading: false,
      downloading: false,
      deleting: false
    }),
    watch: {
      value: {
        handler(v) {
          this.uploadedFile = v;
        },
        immediate: true
      }
    },
    computed: {
      acceptFiles() {
        return "application/pdf,application/msword,text/plain,application/vnd.openxmlformats-officedocument.wordprocessingml.document";
      }
    },
    methods: {
      pickFile() {
        this.$refs.file.click();
      },
      validateFile(file) {
        return new Promise((resolve, reject) => {
          const isFormalExtension =
            file.type === "application/pdf" ||
            file.type === "application/msword" ||
            file.type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
          const isLt2M = file.size / 1024 / 1024 < 4;
          if (!isFormalExtension) {
            reject({ message: "avatar_file_format_error" });
          }
          if (!isLt2M) {
            reject({ message: "less_than_2_mb_error" });
          }
          if (isFormalExtension && isLt2M) {
            resolve(isFormalExtension && isLt2M);
          }
          resolve(false);
        });
      },
      onFilePicked(e) {
        const file = e.target.files[0];
        if (file !== undefined) {
          this.validateFile(file)
            .then((valid) => {
              if (valid) {
                this.fileUploadValue = 0;
                const fr = new FileReader();
                fr.readAsDataURL(file);
                fr.addEventListener("load", () => {
                  this.fileUrl = fr.result;
                  const fd = new FormData();
                  fd.append("file", file, file.name);
                  fd.append("storageType", "DOCUMENT");
                  this.fileUploading = true;
                  this.$api.storageService
                    .upload(fd, {
                      onUploadProgress: (uploadEvent) => {
                        this.fileUploadValue = Math.round((uploadEvent.loaded / uploadEvent.total) * 100);
                      }
                    })
                    .then(({ data, status }) => {
                      if (status === 201) {
                        this.uploadedFile = data.data;
                        this.$emit("input", data.data);
                        this.$emit("save");
                        this.fileUrl = null;
                        this.$refs.file.value = null;
                        this.fileUploadValue = 100;
                        this.fileUploading = false;
                      }
                    })
                    .catch((e) => {
                      this.$helpers.showNotification(e.message);
                    });
                });
              }
            })
            .catch((error) => {
              this.$refs.file.value = null;
              this.fileUrl = null;
              this.$eventBus.$emit("snack-bar-notification", {
                text: error.message,
                color: "error",
                type: "error"
              });
            });
        } else {
          this.fileUrl = null;
        }
      },
      onDelete() {
        this.deleting = true;
        this.$api.storageService
          .delete("DOCUMENT", this.uploadedFile)
          .then(({ data, status }) => {
            if (status === 204) {
              this.fileUrl = null;
              this.$emit("input", null);
              this.$emit("save");
            }
          })
          .catch((e) => {
            this.$helpers.showNotification(e.message);
          })
          .finally(() => {
            this.deleting = false;
          });
      }
    }
  };
</script>
